import productReducer from './productReducer';
import cartReducer from './cartReducer';
import categoryReducer from './categoryReducer';
import bookDetailsReducer from './bookDetailsReducer';
import keyBooksReducer from './keyBooksReducer';
import downloadPdfListReducer from './downloadPdfReducer';
import orderIdReducer from './orderIdReducer';
import { combineReducers } from 'redux';
import { SHOW_SEARCH_PAGE, SEARCH_VALUE } from "../constants/action-types";

const initialSearchPageState = {
    searchValue: ''
};

const searchPgVisibility = {
    searchPageVisibility: false
};

function searchReducer(state = initialSearchPageState, action) {
    switch (action.type) {
        case SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.value
            };

        default:
            return state;
    }
}

function searchVisibilityReducer(state = searchPgVisibility, action) {
    switch (action.type) {
        case SHOW_SEARCH_PAGE:
            return { searchPageVisibility: action.visibility }
        default:
            return state;
    }
}


export const rootReducer = combineReducers({
    product: productReducer,
    cart: cartReducer,
    searchReducer: searchReducer,
    searchVisibilityReducer: searchVisibilityReducer,
    categoryReducer: categoryReducer,
    bookDetailsReducer: bookDetailsReducer,
    downloadPdfListReducer: downloadPdfListReducer,
    keyBooksReducer: keyBooksReducer,
    orderIdReducer: orderIdReducer
});



export default rootReducer;