import React, { Component } from 'react';
import FeaturedProductsCard from './featuredProductsCard';
import './featuredProducts.scss';

export class featuredProducts extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <FeaturedProductsCard {...this.props} />
            </>
        )
    }
}

export default featuredProducts
