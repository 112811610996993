import { GET_LAST_ORDER_ID, GET_LAST_GUEST_ORDER_ID } from '../constants/action-types'

const initialState = {
    orderId: '',
    guestOrderId: '',
    loading: true
};

const orderIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LAST_ORDER_ID:
            return {
                ...state,
                orderId: action.payload,
                loading: false
            };

        case GET_LAST_GUEST_ORDER_ID:
            return {
                ...state,
                guestOrderId: action.payload,
                loading: false
            };

        default:
            return state;
    }
}
export default orderIdReducer;