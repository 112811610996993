import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Alert, Card, ListGroup, Table } from 'react-bootstrap';
import { strip_html_tags } from '../commonActions';
import { Breadcrumbs2 } from '../components/breadCrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './bookDetails.scss';

export class BookDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailId: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var path = this.props.history.location.pathname;
        let searchString = parseInt(path.split(':')[1])
        this.setState({
            detailId: searchString
        })
    }

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }

    render() {
        let booksArr = this.props.books.books;
        let relatedBooksArr = this.props.books.relatedBooks;
        let newRelBooksArr = [];
        newRelBooksArr = relatedBooksArr.filter((item) => {
            if (item.bm_id == this.state.detailId) {
                return newRelBooksArr.push(item);
            }
        })
        const history = this.props.history;

        return (
            <>
                <Breadcrumbs2 pageTitle="Search" />
                <section className="contentSection bookDetailPage">
                    <Container>
                        <div className="shadowContentSection">
                            <Row>
                                {booksArr.length && booksArr.map((val) => {
                                    return (
                                        <>
                                            {val.id == this.state.detailId
                                                && val.status === 'Active'
                                                &&
                                                <Card key={val.id} className="bookGrid p-0 border-0">
                                                    <Card.Body className="p-0 d-flex">
                                                        <Col md={5} className="h-100">
                                                            {val.image &&
                                                                <div className="bookImgBox h-100">
                                                                    <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="English" />
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <div className="bookDesc">
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    {val.title &&
                                                                        <h4>{val.title}

                                                                            <small>
                                                                                {newRelBooksArr.length === 1 &&
                                                                                    <span>Pages: {newRelBooksArr[0].page} | </span>
                                                                                }
                                                                                {newRelBooksArr.length === 1 &&
                                                                                    <span>
                                                                                        {newRelBooksArr[0].color}
                                                                                    </span>
                                                                                }
                                                                            </small>

                                                                        </h4>
                                                                    }
                                                                    {val.class_f ?
                                                                        <p className="mb-0 ml-1">{val.class_f}</p>
                                                                        :
                                                                        <>{val.subcat_name &&
                                                                            <p className="mb-0 ml-1">{val.subcat_name}</p>
                                                                        }
                                                                        </>
                                                                    }
                                                                </div>
                                                                {newRelBooksArr.length === 1 &&
                                                                    <div className="d-flex justify-content-between align-items-start">
                                                                        <h3>
                                                                            <FontAwesomeIcon className="ico" icon="rupee-sign"></FontAwesomeIcon>
                                                                            {newRelBooksArr[0].price}</h3>
                                                                    </div>
                                                                }
                                                                {val.details &&
                                                                    <p className="detail">{strip_html_tags(val.details)}</p>
                                                                }
                                                                <ListGroup className="bookMeta" horizontal>
                                                                    {val.team &&
                                                                        <ListGroup.Item>
                                                                            <span className="author-title">Author</span>
                                                                            <span className="author-name">{strip_html_tags(val.team)}</span>
                                                                        </ListGroup.Item>
                                                                    }
                                                                    {val.size2 &&
                                                                        <ListGroup.Item>
                                                                            <span className="author-title">Size</span>
                                                                            <span className="author-name">{val.size2}</span>
                                                                        </ListGroup.Item>
                                                                    }
                                                                    {newRelBooksArr.length === 1 &&
                                                                        <ListGroup.Item>
                                                                            <span className="author-title">ISBN</span>
                                                                            <span className="author-name">{newRelBooksArr[0].isbn}</span>
                                                                        </ListGroup.Item>
                                                                    }
                                                                </ListGroup>

                                                                {newRelBooksArr.length > 1 &&

                                                                    <Table className="detailsTable" size="sm" striped>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Book No.</th>
                                                                                <th>ISBN</th>
                                                                                <th>PAGES</th>
                                                                                <th>COLOUR</th>
                                                                                <th>PRICE</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {newRelBooksArr.map((rb, index) => {
                                                                                return (
                                                                                    rb.bm_id === val.id &&

                                                                                    <tr>
                                                                                        <td>{rb.sn}</td>
                                                                                        <td>
                                                                                            <Link key={rb.id} to={"/moreDetail/:" + rb.id} >
                                                                                                {rb.isbn}
                                                                                            </Link>
                                                                                        </td>

                                                                                        <td>{rb.page}</td>
                                                                                        <td>{rb.color}</td>
                                                                                        <td>{rb.price}</td>
                                                                                    </tr>

                                                                                )
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                }
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-end">
                                                                <Button onClick={history.goBack}>Go Back</Button>
                                                            </div>
                                                        </Col>
                                                    </Card.Body>
                                                </Card>

                                            }
                                        </>
                                    )
                                })
                                }
                            </Row>
                        </div>
                    </Container>
                </section>
            </>

        )
    }
}


function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}


export default connect(mapStateToProps)(BookDetails);
