import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import { dynamicSort } from '../commonActions';
import './categories.scss';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 8
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group">
            <button className={currentSlide == 0 ? 'disable' : ''} onClick={() => previous()}>
                <FontAwesomeIcon icon="angle-left" />
            </button>
            <button onClick={() => next()}>
                <FontAwesomeIcon icon="angle-right" />
            </button>
        </div>
    );
};

export class CategoryCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uniqueClass: [],
            uniqueSubject: []
        }
    }

    componentDidMount() {
        //CLASS FILTER
        let dataArr = this.props.books.books;
        let newClassArr = [];
        dataArr.map((item) => {
            if (item.cat_id == 2 && item.status === 'Active') {
                if (item.subcat_name.includes("Class : ", "")) {
                    item.subcat_name = item.subcat_name.replace("Class : ", "")
                }
                return newClassArr.push(item);
            }
        })
        var newClassMapArr = newClassArr.map((item) => {
            return [item.subcat_name, item]
        });
        var newDataArr = new Map(newClassMapArr);
        var uniqueClass = [...newDataArr.values()].sort(function (a, b) {
            return a.home_show_order - b.home_show_order;
        });

        //SUBJECT FILTER
        let newSubjectArr = [];
        dataArr.map((item) => {
            if (item.cat_id == 1 && item.status === 'Active') {
                return newSubjectArr.push(item);
            }
        })
        var newSubjectMapArr = newSubjectArr.map((item) => {
            return [item.subcat_name, item]
        });

        var newSubDataArr = new Map(newSubjectMapArr);
        var uniqueSubject = [...newSubDataArr.values()].sort(dynamicSort("subcat_name"));


        this.setState({
            uniqueClass: uniqueClass,
            uniqueSubject: uniqueSubject
        })
    }

    render() {
        return (
            <>
                <section className="sectionPadding">
                    <Container>
                        <div className="categoryHead">
                            <h6>View By</h6>
                            <h2>{this.props.catTitle} Books</h2>
                        </div>
                        {this.props.catTitle == 'Subject Wise' &&
                            <div className="categorySlider">
                                <Carousel
                                    renderButtonGroupOutside={true}
                                    arrows={false}
                                    responsive={responsive}
                                    customButtonGroup={this.state.uniqueSubject.length > 6 ? <ButtonGroup /> : ''}
                                    itemClass="pr-3"
                                >
                                    {
                                        this.state.uniqueSubject.map((val, index) => {
                                            return (
                                                <div key={val.id} className="subjectWiseImage">
                                                    <Link to={"/searchCat/:" + val.subcat_name + '&' + val.cat_id + '&' + val.subcat_id}>
                                                        <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="Image" />
                                                    </Link>
                                                    <h2>{val.subcat_name}</h2>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </Carousel>
                            </div>
                        }

                        {this.props.catTitle == 'Class Wise' &&
                            <div className="categorySlider">
                                <Carousel
                                    renderButtonGroupOutside={true}
                                    arrows={false}
                                    responsive={responsive}
                                    customButtonGroup={this.state.uniqueClass.length > 6 ? <ButtonGroup /> : ''}
                                    itemClass="pr-3"
                                >
                                    {
                                        this.state.uniqueClass.map((val, index) => {
                                            return (
                                                <div key={val.id} className="classBoard">
                                                    <Link to={"/searchCat/:" + val.subcat_name + '&' + val.cat_id + '&' + val.subcat_id}>
                                                        {val.subcat_name}
                                                    </Link>
                                                    <h2>{val.subcat_name}</h2>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </Carousel>
                            </div>
                        }
                    </Container>
                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}

export default connect(mapStateToProps)(CategoryCard);
