import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Alert, Card, Button, Table } from 'react-bootstrap';
import { updateCartQuantity, removeFromCart, cartTotal, qtyBoxFocus } from '../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Item extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quantity: this.props.item.quantity,
            btnVisible: false
        };
    }

    handleChange = (e) => {
        if (this.state.quantity != e.target.value) {
            this.setState({
                quantity: e.target.value,
                btnVisible: true
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.quantity <= 0) {
            this.setState({
                error: "Quantity must be greater than or equal to 1"
            })
            return;
        } else if (this.state.quantity > this.props.item.product.amount) {
            this.setState({
                error: "You have exceeded the maximum number of allowed quantity!"
            })
            return;
        }

        this.props.updateCartQuantity(this.props.item.product.id, this.state.quantity, this.props.total);

        this.setState({
            btnVisible: false
        });
    }

    handleRemove = (e) => {
        this.props.handleRemove(this.props.item.product.id);
    }

    render() {

        const { item, index } = this.props;

        return (
            <tr>
                <td className="text-center">{index + 1}</td>
                <td>
                    {item.product.title}
                </td>
                <td>
                    {item.product.price}
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="number"
                        name="quantity"
                        required
                        onChange={this.handleChange}
                        defaultValue={this.state.quantity}
                        isInvalid={!!this.state.error}
                        placeholder="" />
                    <Form.Control.Feedback type="invalid">
                        {this.state.error ? this.state.error : "Please enter quantity"}
                    </Form.Control.Feedback>

                </td>
                <td>
                    {
                        this.state.btnVisible ? (
                            <Button className="mr-2" size="sm" variant="info" type="submit"
                                onClick={this.handleSubmit}>Update</Button>
                        ) : null
                    }
                    <Button size="sm" type="button" onClick={this.handleRemove}>
                        <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                    </Button>
                </td>
            </tr>

        )
    }
}

const mapStateToProps = (state) => {

    return {
        cart: state.cart
    }
};

const mapDispatchToProps = (dispatch) => {

    return {
        updateCartQuantity: (productId, quantity) => dispatch(updateCartQuantity(productId, quantity)),
        removeFromCart: (productId) => dispatch(removeFromCart(productId)),
        cartTotal: (total) => dispatch(cartTotal(total))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);