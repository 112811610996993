import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { Breadcrumbs2 } from './breadCrumbs';
import { strip_html_tags } from '../commonActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dynamicSort } from '../commonActions';
import { getBookDetails, getCategories, searchVal, setSearchPageVisibility, getKeyBookDetails, getDownloadPdfList } from '../actions';
import './search.scss';

export class SearchedProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredBooks: [],
            searchVal: this.props.searchValue.toLowerCase()
        }
        this.hideSearchPage = this.hideSearchPage.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    hideSearchPage() {
        this.props.hideSearchPage();
    }

    goBack() {
        let history = this.props.history;
        this.props.setSearchPageVisibility(false)
        this.props.searchVal('');
        history.goBack()
    }

    render() {
        let history = this.props.history;
        let booksArr = this.props.books.books;
        let filteredBooks = [];
        let uniqueBookList = [];
        filteredBooks = booksArr.filter((item) => {
            if (item.cat_id == 2 && item.status === 'Active' && item.pr_deactivate == 0) {
                return Object.values(item).toString().toLowerCase().includes(this.props.searchValue.toLowerCase())
            }
        })
        uniqueBookList = [...new Map(filteredBooks.map(item => [item['title'], item])).values()]
        var sortedUniqueFilteredBooksArr = [...uniqueBookList.values()].sort(dynamicSort("title"));


        return (
            <>
                <Breadcrumbs2 pageTitle="Search" />
                <section className="contentSection searchPage">
                    <Container>
                        <div className="shadowContentSection">
                            <div className="categoryHead d-flex justify-content-between">
                                <h2>Search Result</h2>
                                <Button onClick={this.goBack}>Go Back</Button>
                            </div>
                            <Row xs={1} md={2}>
                                {sortedUniqueFilteredBooksArr.length > 0 &&
                                    sortedUniqueFilteredBooksArr.map((val, index) => (
                                        <Link
                                            to={"/bookDetail/:" + val.id}>
                                            <Col className="mb-3">
                                                <div className="featuredProductsBox h-100">
                                                    {val.image &&
                                                        <div className="featuredImage">
                                                            <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="" />
                                                        </div>
                                                    }
                                                    <div className="featuredContent">
                                                        {val.title &&
                                                            <h3>{val.title}</h3>
                                                        }
                                                        {val.details &&
                                                            <p>{strip_html_tags(val.details).substr(0, 120)}...</p>
                                                        }
                                                        <p className="link">See More <FontAwesomeIcon icon="angle-double-right" /></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Link>
                                    ))}
                            </Row>
                            {(!sortedUniqueFilteredBooksArr ||
                                sortedUniqueFilteredBooksArr.length === 0 ||
                                sortedUniqueFilteredBooksArr == []) &&
                                <Row>
                                    <Alert variant="danger" className="w-100 text-center">
                                        <Alert.Heading>No record found!</Alert.Heading>
                                        <p className="mb-0">Please try with any other keyword</p>
                                    </Alert>

                                </Row>
                            }
                        </div>
                    </Container>
                </section>
            </>

        )
    }
}


function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        searchVal: (value) => dispatch(searchVal(value)),
        setSearchPageVisibility: (value) => dispatch(setSearchPageVisibility(value)),
        getBookDetails: () => dispatch(getBookDetails()),
        getCategories: () => dispatch(getCategories()),
        getKeyBookDetails: () => dispatch(getKeyBookDetails()),
        getDownloadPdfList: () => dispatch(getDownloadPdfList())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchedProducts);

