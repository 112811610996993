import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Item from './item';
import { connect } from 'react-redux';
import { Card, Row, Table, Form } from 'react-bootstrap';

class Cart extends Component {


    render() {
        const cart = this.props.cart.cart.length > 0 ? (
            <>
                <tbody>
                    {
                        this.props.cart.cart.map((item, index) => {
                            return (
                                <Item key={index} item={item} handleRemove={this.props.handleRemove} total={this.props.cart?.total} index={index} />
                            )
                        })
                    }
                </tbody>

                <tfoot className="bg-light">
                    <tr>
                        <td colSpan="5">
                            <h6 className="text-right mb-0 py-1">Total
                                <FontAwesomeIcon className="ml-4" icon="rupee-sign"></FontAwesomeIcon>
                                <strong className="ml-1">{this.props.cart?.total?.toFixed(2)}</strong>
                            </h6>
                        </td>
                    </tr>
                </tfoot>
            </>

        ) : (
            <Card.Body>
                <p>Cart is empty</p>
            </Card.Body>
        )

        return (
            <>
                <small className='d-block text-danger text-right mb-2'><strong><i>*Please click on Quantity Box below to change quantity</i></strong></small>
                <Table responsive size="sm" bordered className="cartTable mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th width="5%" className="text-center">#</th>
                            <th>Name of the Book</th>
                            <th>Price</th>
                            <th width="10%">Quantity</th>
                            <th width="10%">Action</th>
                        </tr>
                    </thead>
                    {cart}

                </Table>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart
    }
};


export default connect(mapStateToProps)(Cart)



