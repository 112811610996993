import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import PageBanner from '../components/pageBanner';
import './about.scss';

export class About extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let booksArr = this.props.books.books;

        return (
            <section className="aboutPage">
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h2>PROGRESS BOOKS <span>BETTER</span> BOOKS</h2>
                                <p>Progress Publishers is dedicated in the field of education since 1976 by providing books of high quality at very reasonable prices. It has an established track record of maintaining international standards in publishing.</p>
                                <p>Our books are well appreciated and recommended in reputed schools throughout the nation who value education. The books are written and edited by qualified and dedicated teachers, professionals who are specialised in their respective fields. These books employ the most modern and proven approaches towards the shaping of young minds.</p>
                                <p>The books are updated as and when required meeting the current standards of education. They are prepared in strict accordance with the syllabus laid down by the NCERT, CBSE, ICSE, MATRICULATION and various STATE BOARDS.</p>
                            </Col>
                            <Col>
                                <img src={process.env.REACT_APP_PUBLICURL + "images/about.png"} alt="Progress Pulishers" />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="sectionPadding">
                    <Container>
                        <Card className="text-center p-2 aboutCardStats">
                            <blockquote className="blockquote mb-0 card-body">
                                <Row>
                                    <Col sm={6}>
                                        <div className="aboutIcon">
                                            <div className="icoBox orange">
                                                <FontAwesomeIcon icon="book-reader" />
                                            </div>
                                            <h4><CountUp delay={1} end={booksArr.length} /></h4>
                                            <h5>Books to Read</h5>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="aboutIcon">
                                            <div className="icoBox pink">
                                                <FontAwesomeIcon icon="clipboard-check" />
                                            </div>
                                            <h4><CountUp delay={1} end={15000} />+</h4>
                                            <h5>Orders Completed</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </blockquote>
                        </Card>
                    </Container>
                </section>
            </section>
        )
    }
}


function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}


export default connect(mapStateToProps)(About);
