import React, { Component } from 'react';
import { Form, Table } from 'react-bootstrap';
import Product from './product';
import Cart from './cart';
import { connect } from 'react-redux';
import { dynamicSort } from '../commonActions';
import { addToCart, cartTotal, removeFromCart } from "../actions";


class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inCartValue: this.props.inCartValue ? this.props.inCartValue : false
        }
    }

    addToCart = (product) => {
        this.props.addToCart(product);

        let total = 0;
        this.props.cart.cart.map(item => total += item.product.price * item.quantity);
        this.setState({
            total: total
        })
        this.props.cartTotal(total);
    }

    handleRemove = (e) => {
        this.props.removeFromCart(e);
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            selectedBook: value
        })
    }

    render() {
        let booksArr = this.props.books;
        let classWiseBooksArr = booksArr.filter((item) => {
            if (item.cat_id === "2") {
                return item;
            }

        });

        let uniqueBookList = [...new Map(classWiseBooksArr.map(item => [item['title'], item])).values()]
        var sortedBookList = [...uniqueBookList.values()].sort(dynamicSort("title"));

        return (
            <>
                {this.props.cart.cart.length > 0 &&
                    <div className="mx-2 mb-2">
                        <Cart key={this.props.cart?.total} total={this.props.cart?.total} handleRemove={this.handleRemove} />
                    </div>

                }

                <div className="px-2">
                    <Table size="sm">
                        <tbody>
                            <tr>
                                <td className="p-0">
                                    <Form.Label>Select product to add</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        as="select"
                                        defaultValue={this.state.selectedBook}
                                        name="bookName"
                                        onChange={this.handleChange}
                                        required={!this.state.inCartValue}>
                                        <option value="">...Select...</option>
                                        {
                                            sortedBookList.map(product =>
                                                <option key={product.id} value={product.id}>{product.title}</option>
                                            )}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Please select product</Form.Control.Feedback>
                                </td>
                                {
                                    this.props.books.map(product => {
                                        return (
                                            this.state.selectedBook == product.id &&
                                            <Product key={product.id} product={product} addToCart={this.addToCart} inCart={this.props.cart.cart.length > 0 && this.props.cart.cart.filter(e => e.product.id === product.id).length > 0} />
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </Table>
                </div>


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        books: state.bookDetailsReducer.allBooks,
        cart: state.cart
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product) => dispatch(addToCart(product)),
        removeFromCart: (productId) => dispatch(removeFromCart(productId)),
        cartTotal: (total) => dispatch(cartTotal(total))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductList)