import React, { Component } from 'react';
import { Container, Col, Form, Alert, Button, Table, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import PageBanner from '../components/pageBanner';
import ProductList from '../components/productList';
import { connect } from 'react-redux';
import { addToCart, resetCart, getLastOrderId } from "../actions";
import './orderOnline.scss';
const { REACT_APP_APIPATH } = process.env;

export class OrderOnline extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            title: '',
            f_name: '',
            l_name: '',
            instituteName: '',
            address: '',
            city: '',
            district: '',
            pinCode: '',
            stateName: '',
            country: '',
            phone: '',
            email: '',
            dispatchMode: '',
            transportName: '',
            remarks: '',
            orderDetails: [],
            orderId: '',
            validated: false,
            sending: false
        }
        this.prev = this.prev.bind(this);
        this.deleteAllCartItems = this.deleteAllCartItems.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({
            validated: true
        })

        if (this.state.currentStep === 1 &&
            form.checkValidity() === true) {

            let formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries())

            let currentStep = this.state.currentStep
            currentStep = currentStep >= 1 ? 2 : currentStep + 1
            this.setState({
                currentStep: currentStep,
                step1FormData: formDataObj
            })
        }


        if (this.state.currentStep === 2 &&
            form.checkValidity() === true
        ) {
            let orderDetails = [];
            let total = '';
            if (this.props.cart.length > 0) {
                this.props.cart.map((product, i) => {
                    return (
                        <>
                            {
                                orderDetails.push({
                                    bookQuantity: product.quantity,
                                    bookName: product.product.title,
                                    bookPrice: product.product.price
                                })
                            }
                        </>

                    )
                }
                )
                total = this.props.total
                orderDetails = [{ books: orderDetails }, { total: total }]
                this.setState({
                    orderDetails: orderDetails
                })
            }

            let formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries())

            // let date = new Date(),
            //     randomNumber = Math.floor(Math.random() * (999 - 100 + 1) + 100),
            //     components = [
            //         'O-',
            //         date.getDate(),
            //         (date.getMonth() + 1),
            //         date.getFullYear(),
            //         '-',
            //         randomNumber
            //     ],
            //     combinedNumber = components.join("");



            this.setState({
                step2FormData: { formDataObj }
            },
                () => {
                    this.setState({
                        finalFormData: { ...this.state.step1FormData, ...this.state.step2FormData, ...this.state.orderDetails },
                        sending: true,
                    },
                        () => {
                            Axios.post(REACT_APP_APIPATH + "orderOnline.php", {
                                data: this.state.finalFormData,
                                headers: {
                                    'Content-type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                if (response.status == 200) {
                                    if (response.data.success === 1) {
                                        this.setState({
                                            currentStep: 1,
                                            title: '',
                                            f_name: '',
                                            l_name: '',
                                            instituteName: '',
                                            address: '',
                                            city: '',
                                            district: '',
                                            pinCode: '',
                                            stateName: '',
                                            country: '',
                                            phone: '',
                                            email: '',
                                            dispatchMode: '',
                                            transportName: '',
                                            remarks: '',
                                            orderId: response.data.orderId,
                                            validated: false,
                                            mailSent: true,
                                            sending: false,
                                            errorSending: false
                                        })
                                    }
                                    if (response.data.success == 0) {
                                        this.setState({
                                            mailSent: false,
                                            sending: false,
                                            errorSending: true
                                        })
                                    }
                                }
                                if (response.data.status === 'ERROR') {
                                    this.setState({
                                        currentStep: 1,
                                        title: '',
                                        f_name: '',
                                        l_name: '',
                                        instituteName: '',
                                        address: '',
                                        city: '',
                                        district: '',
                                        pinCode: '',
                                        stateName: '',
                                        country: '',
                                        phone: '',
                                        email: '',
                                        dispatchMode: '',
                                        transportName: '',
                                        remarks: '',
                                        orderId: '',
                                        validated: false,
                                        mailSent: true,
                                        sending: false,
                                        errorSending: true
                                    })
                                }
                            }
                            ).then(() => {
                                this.props.resetCart()
                            })
                        })

                })
        }

    }

    prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        },
            () => {
                this.props.resetCart()
            })
    }


    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-secondary"
                    type="button" onClick={this.prev}>
                    Previous
                </button>
            )
        }
        return null;
    }

    deleteAllCartItems() {
        if (this.state.currentStep === 1) {
            this.props.resetCart()
        }
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 2) {
            return (
                <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={this.deleteAllCartItems}
                >
                    Next
                </button>
            )
        }
        if (currentStep === 2) {
            return (
                <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.sending || false}>
                    Submit

                    {this.state.sending &&
                        <FontAwesomeIcon className="ml-2" icon="spinner" spin />
                    }
                </button>
            )
        }
        return null;
    }

    render() {
        return (
            <section className="orderOnlinePage">
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding">
                    <Container>
                        {
                            !this.state.mailSent &&
                            !this.state.errorSending &&
                            <>
                                <ul className="steps">
                                    <li className={this.state.currentStep == 1 ? 'active' : ''}>
                                        <span className="dot">
                                            <FontAwesomeIcon icon="university" />
                                        </span>
                                        <span>Institute Details</span>
                                    </li>
                                    <li className={this.state.currentStep == 2 ? 'active' : ''}>
                                        <span className="dot">
                                            <FontAwesomeIcon icon="cart-plus" />
                                        </span>
                                        <span>Order Details</span>
                                    </li>
                                </ul>
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <small className="text-right d-block mb-2 font-italic">Fields marked with <span className="required">*</span> are required.</small>
                                    <div className="formBox">
                                        {this.state.currentStep === 1 &&
                                            <>
                                                <Form.Row>
                                                    <Form.Group xs="4" md="2" as={Col} controlId="formGridTitle">
                                                        <Form.Label>Title<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            as="select"
                                                            defaultValue={this.state.title}
                                                            name="title"
                                                            onChange={this.handleChange}
                                                            required>
                                                            <option value="">...Select...</option>
                                                            <option value="Mr.">Mr.</option>
                                                            <option value="Mrs.">Mrs.</option>
                                                            <option value="Ms.">Ms.</option>
                                                            <option value="Sr.">Sr.</option>
                                                            <option value="Fr.">Fr.</option>
                                                            <option value="Br.">Br.</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">Please select title!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridFirstName">
                                                        <Form.Label>First Name<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Enter First Name"
                                                            name="f_name"
                                                            defaultValue={this.state.f_name}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter first name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridLastName">
                                                        <Form.Label>Last Name<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            name="l_name"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.l_name}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter last name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="instituteName">
                                                        <Form.Label>Name of the Institution<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Enter Institute Name"
                                                            name="instituteName"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.instituteName}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid Institute Name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridAddress">
                                                        <Form.Label>Address<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            as="textarea"
                                                            rows={2}
                                                            name="address"
                                                            placeholder="Address"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.address}
                                                            required />
                                                        <Form.Control.Feedback type="invalid">Please enter valid address!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="city">
                                                        <Form.Label>City<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="city"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.city}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid City!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="district">
                                                        <Form.Label>District<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="district"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.district}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid district!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="pinCode">
                                                        <Form.Label>Pin Code<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="pinCode"
                                                            required
                                                            type="number"
                                                            onInput={() => this.value |= 0}
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.pinCode}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid pinCode!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridStateName">
                                                        <Form.Label>State<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="stateName"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.stateName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid State!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridCountry">
                                                        <Form.Label>Country<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            as="select"
                                                            name="country"
                                                            defaultValue={this.state.country}
                                                            onChange={this.handleChange}
                                                            required
                                                        >
                                                            <option value="">(Select Country) </option>
                                                            <option value="India">India </option>
                                                            <option value="Afghanistan">Afghanistan </option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="China">China</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="vietnam">vietnam</option>
                                                            <option value="Others">Others</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please enter valid Country!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridPhone">
                                                        <Form.Label>Phone<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="number"
                                                            placeholder="Enter your phone number"
                                                            name="phone"
                                                            onInput={() => this.value |= 0}
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.phone}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid phone number!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridEmail">
                                                        <Form.Label>Email<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="email"
                                                            name="email"
                                                            defaultValue={this.state.email}
                                                            onChange={this.handleChange}
                                                            required />
                                                        <Form.Control.Feedback type="invalid">Please enter valid email id!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                            </>
                                        }

                                        {this.state.currentStep === 2 &&
                                            <>
                                                <Alert variant="secondary">
                                                    <Form.Row>
                                                        <Form.Group className="mb-0 text-right pr-5" as={Col} controlId="dispatchMode">
                                                            <Form.Label>Disptach Mode</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group className="mb-0" as={Col}>
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                label="Transport"
                                                                type="radio"
                                                                id="inline-radio-1"
                                                                required
                                                                name="dispatchMode"
                                                                checked={this.state.dispatchMode === "Transport"}
                                                                defaultValue="Transport"
                                                                onChange={this.handleChange}
                                                                feedback="Please select dispatch mode"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                custom
                                                                required
                                                                label="Post"
                                                                type="radio"
                                                                name="dispatchMode"
                                                                id="inline-radio-2"
                                                                checked={this.state.dispatchMode === "Post"}
                                                                defaultValue="Post"
                                                                onChange={this.handleChange}
                                                                feedback="Please select dispatch mode"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    {this.state.dispatchMode == "Transport" ?
                                                        <>
                                                            <hr />
                                                            <Form.Row className="mt-3">
                                                                <Form.Group as={Col} controlId="transportName">
                                                                    <Form.Label className="mb-2">If Transport (Transport Name &amp; Destination Station):</Form.Label>
                                                                    <Form.Control
                                                                        size="lg"
                                                                        name="transportName"
                                                                        onChange={this.handleChange}
                                                                        defaultValue={this.state.transportName}
                                                                        required={this.state.dispatchMode == "Transport" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">Please enter transport details</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </>
                                                        :
                                                        ""}
                                                </Alert>

                                                <Form.Group controlId="formGridRemarks">
                                                    <Form.Label>Remarks</Form.Label>
                                                    <Form.Control
                                                        size="lg"
                                                        as="textarea"
                                                        rows={2}
                                                        name="remarks"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.remarks}
                                                        placeholder="" />
                                                </Form.Group>
                                                <Card className="p-0">
                                                    <Alert variant="info" className="mb-2">
                                                        <Alert.Heading>Order Details!</Alert.Heading>
                                                    </Alert>
                                                    <ProductList inCartValue={this.props.cart.length > 0 ? true : false} />
                                                </Card>
                                            </>
                                        }
                                    </div>
                                    <div className={this.state.currentStep == 1 ? 'bottomButtons justify-content-right' : 'bottomButtons'}>
                                        {this.previousButton()}
                                        {this.nextButton()}
                                    </div>
                                </Form>
                            </>
                        }

                        {this.state.mailSent &&
                            !this.state.errorSending &&
                            <div className="formBox">
                                {this.state.step2FormData && this.state.orderId &&
                                    <Alert className="m-4" variant="success">
                                        <Alert.Heading>Thank You!</Alert.Heading>
                                        <p>Please note down your order id : <strong className="text-uppercase">"{this.state.orderId}"</strong> for future reference.</p>
                                    </Alert>
                                }
                            </div>
                        }

                        {this.state.errorSending &&
                            <div className="formBox">
                                <Alert className="m-4" variant="danger">
                                    <Alert.Heading>Some error occured!</Alert.Heading>
                                    <p>Please try again after sometime.</p>
                                </Alert>
                            </div>
                        }
                    </Container>
                </section>
            </section >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        books: state.bookDetailsReducer,
        cart: state.cart.cart,
        total: state.cart.total,
        lastOrderId: state.orderIdReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product) => {
            dispatch(addToCart(product));
        },
        resetCart: () => {
            dispatch(resetCart())
        },
        getLastOrderId: () => {
            dispatch(getLastOrderId())
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderOnline)


