export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function strip_html_tags(str) {
    if ((str == null) || (str == ''))
        return false;
    else
        str = str.toString();
    return str.replace(/<[^>]*>/g, '');
}