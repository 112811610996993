const initialState = {
    cart: [],
    total: ''
};

const cartReducer = (state = initialState, action) => {

    let cart = state.cart;

    switch (action.type) {

        case 'ADD_TO_CART':

            cart.push(action.payload);
            return {
                ...state,
                cart: cart
            };

        case 'UPDATE_CART_QUANTITY':

            let item = cart.find(item => item.product.id == action.payload.productId);

            let newCart = cart.filter(item => item.product.id != action.payload.productId);

            item.quantity = action.payload.quantity;

            newCart.push(item);

            let ucTotal;
            if (item.quantity > 1) {
                const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);
                ucTotal = sumValues(newCart.map(item => item.product.price * item.quantity))
            }
            return {
                ...state,
                cart: newCart,
                total: ucTotal
            };

        case 'CART_TOTAL':
            let total = action.payload.total;
            return {
                ...state,
                total: total
            };

        case 'REMOVE_FROM_CART':
            let crTotal;
            let filteredCart = cart.filter(item => item.product.id != action.payload.productId)
            if (filteredCart.length > 0) {
                const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);
                crTotal = sumValues(filteredCart.map(item => item.product.price * item.quantity))
            }

            return {
                ...state,
                cart: filteredCart,
                total: crTotal
            };

        case 'RESET_CART':
            return {
                ...state,
                cart: [],
                total: ''
            };

        default:
            return state;
    }
};

export default cartReducer;