import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav, Form, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './header.scss';


export class Header extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <header>
                <Navbar className="top-header" expand="lg">
                    <Container>
                        <Navbar.Toggle aria-controls="top-nav" />
                        <Navbar.Collapse id="top-nav">
                            <Nav activeKey={this.props.location.pathname} className="ml-auto" onClick={this.props.hideSearchPage}>
                                <Nav.Link href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList?.pdfList[0]?.catalogue}>Catalogue</Nav.Link>
                                <Nav.Link href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList?.pdfList[0]?.priceList}>Price list</Nav.Link>
                                <Nav.Link href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList?.pdfList[0]?.bookList}>Book List</Nav.Link>
                                <Nav.Link href="/keyBooks">Keybooks</Nav.Link>
                                <Nav.Link href="/download">Download</Nav.Link>
                                <Nav.Link href="/print">Print/Publish</Nav.Link>
                                <Nav.Link href="/contact">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Navbar className="main-nav" expand="lg">
                    <Container>
                        <Navbar.Brand href="/" onClick={this.props.hideSearchPage}>
                            <img src={process.env.REACT_APP_PUBLICURL + "/images/logo.png"} alt="Progress Publishers" />
                        </Navbar.Brand>
                        <Form inline className="mr-auto">
                            <InputGroup onKeyDown={(event) => this.props.mainSearchEnter(event)}>
                                <FormControl id="inlineFormInputGroup" value={this.props.searchVal} placeholder="Enter a keyword" onChange={(event) => this.props.changeHandler(event)} />
                                <InputGroup.Append onClick={this.props.mainSearchClick}>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon="search" />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav activeKey={this.props.location.pathname} className="ml-auto">
                                <Nav.Link href="/" onClick={this.props.hideSearchPage}>
                                    <FontAwesomeIcon icon="home" />
                                </Nav.Link>
                                <Nav.Link href="/about" onClick={this.props.hideSearchPage}>About Us</Nav.Link>
                                <Nav.Link href="/guestBook" onClick={this.props.hideSearchPage}>Guest Book</Nav.Link>
                                <Nav.Link href="/orderOnline" onClick={this.props.hideSearchPage}>Order Online</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        downloadPdfList: state.downloadPdfListReducer
    };
}

export default connect(mapStateToProps)(withRouter(Header));
