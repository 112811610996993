import { GET_CATEGORIES } from '../constants/action-types'

const initialState = {
    categories: [],
    loading: true
};

const categoryReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                loading: false
            };

        default:
            return state;
    }
}
export default categoryReducer;