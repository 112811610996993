import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBanner from '../components/pageBanner';
import './print.scss';

export class Print extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <section className="printPage">
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding  pb-5">
                    <Container>
                        <Row>
                            <Col md={7}>
                                <h2><span>CREATIVE ART PRESS</span></h2>
                                <h6>
                                    <span className="icon"><FontAwesomeIcon icon="map-pin" /></span>
                                    <span className="side-text">58, FIE, Patparganj Industrial Area, Delhi 110092 (INDIA)</span>
                                </h6>
                                <h6 className="mb-4">
                                    <span className="icon"><FontAwesomeIcon icon="phone-alt" /></span>
                                    <span className="side-text">011-49094422</span>
                                </h6>
                                <p>Established in 2000, Creative Art Press is offering high-quality print services to its clientele in India. Focus, commitment and dedication are the key components of Creative’s philosophy, which have given it the strength to grow over the past years in delivering good quality and total customer satisfaction. Equipped with an unmatched technology / infrastructure, processes and people, Creative Art Press provides cutting-edge printing services to its clients. We undertake Printing and Publishing work.</p>
                            </Col>
                            <Col md={5}>
                                <img className="w-100 d-block" src={process.env.REACT_APP_PUBLICURL + "images/print.jpg"} alt="Progress Pulishers" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </section>
        )
    }
}


export default Print

