import React, { Component } from 'react';
import './App.scss';

// Fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';

import { getBookDetails, getCategories, getKeyBookDetails } from './actions';


import Layout from './pages/layout';

//Add fontawesome icons to library to initialize its usage
library.add(fab, fas);

export class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Layout {...this.props} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBookDetails: () => dispatch(getBookDetails()),
    getCategories: () => dispatch(getCategories()),
    getKeyBookDetails: () => dispatch(getKeyBookDetails())
  }
};

export default connect(null, mapDispatchToProps)(App);

