import { GET_DOWNLOAD_PDFS } from '../constants/action-types'

const initialState = {
    pdfList: [],
    loading: true
};

const downloadPdfListReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_DOWNLOAD_PDFS:
            return {
                ...state,
                pdfList: action.payload,
                loading: false
            };

        default:
            return state;
    }
}
export default downloadPdfListReducer;