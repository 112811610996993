import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import rootReducer from "./reducers";

const persistConfig = {
    key: 'root',
    storage: sessionStorage
};

const middleware = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
    const store = createStore(
        persistedReducer,
        applyMiddleware(...middleware)
    );
    const persistor = persistStore(store);

    return { persistor, store };
};

export default configureStore;