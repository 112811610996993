import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PageBanner from '../components/pageBanner';
import { getKeyBookDetails } from '../actions';
import './keyBooks.scss';

export class KeyBooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyBooks: []
        }
    }

    componentDidMount() {
        let keyBooks = this.props.keyBooks.keyBooks;
        var seen = {};
        let data;
        data = keyBooks.filter(function (entry) {
            if (entry.status === '1') {
                var previous;
                if (seen.hasOwnProperty(entry.kb_title)) {
                    previous = seen[entry.kb_title];
                    previous.data.push({ kb_link: entry.kb_link, kb_link_title: entry.kb_link_title });
                    return false;
                }
                if (!Array.isArray(entry.kb_link, entry.kb_link_title)) {
                    entry.data = [{ kb_link: entry.kb_link, kb_link_title: entry.kb_link_title }];
                }
                seen[entry.kb_title] = entry;
                return true;
            }
        }).sort((a, b) =>
            a.kb_title.localeCompare(b.kb_title)
        );

        this.setState({
            keyBooks: data
        })
    }


    render() {
        return (
            <>
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding keyBookPage pb-5">
                    <Container>
                        {
                            this.state.keyBooks.length &&
                            this.state.keyBooks.map((book, index) => {
                                return (
                                    <div key={index} className="download-item">
                                        <span className="download-item-icon">
                                            {index + 1}
                                        </span>
                                        <aside>
                                            <h2>{book.kb_title}</h2>
                                            <div className="btn-group-w-gap" >
                                                {
                                                    book.data.length &&
                                                    book.data.map((part, i) => {
                                                        return (
                                                            <Button key={i} href={process.env.REACT_APP_PUBLICURL + "pdf/keyBooks/" + part.kb_link} size="sm">
                                                                {part.kb_link_title}
                                                            </Button>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </aside>
                                    </div>
                                )
                            })
                        }
                    </Container>
                </section>
            </>
        )
    }
}



function mapStateToProps(state) {
    return {
        keyBooks: state.keyBooksReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKeyBookDetails: () => dispatch(getKeyBookDetails())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(KeyBooks);
