const initialState = {
    books: []
};

const productReducer = (state = initialState, action) => {
    return state;
};



export default productReducer;