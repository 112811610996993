import React, { Suspense, lazy, Component, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import { getBookDetails, getCategories, searchVal, setSearchPageVisibility, getKeyBookDetails, getDownloadPdfList, getLastOrderId, getLastGuestOrderId } from '../actions';

import Home from './home';
import About from './about';
import GuestBook from './guestBook';
import OrderOnline from './orderOnline';
import Download from './download';
import KeyBooks from './keyBooks';
import Contact from './contact';
import Print from './print';
import SearchCat from './searchCat';
import BookDetails from './bookDetail';
import MoreDetails from './moreDetail';
import NotFound from './404';
import Header from '../components/header';
import SearchedProducts from '../components/search';
import Footer from '../components/footer';

export class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredBooks: []
        }

        this.mainSearchClick = this.mainSearchClick.bind(this);
        this.mainSearchEnter = this.mainSearchEnter.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.hideSearchPage = this.hideSearchPage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getCategories();
        this.props.getBookDetails();
        this.props.getKeyBookDetails();
        this.props.getDownloadPdfList();
        this.props.getLastOrderId();
        this.props.getLastGuestOrderId();
    }

    mainSearchEnter = (event) => {
        let history = createBrowserHistory();
        if (this.props.searchValue !== '' ||
            this.props.searchValue !== undefined ||
            this.props.searchValue !== null) {
            if (event.keyCode == 13) {
                event.preventDefault();
                this.props.setSearchPageVisibility(true)
                window.location = "/search"
            }
        }
    }

    mainSearchClick = () => {
        if (this.props.searchValue !== '' ||
            this.props.searchValue !== undefined ||
            this.props.searchValue !== null) {
            this.props.setSearchPageVisibility(true)
            window.location = "/search"
        }
        else {
            alert('Please enter a keyword')
        }
    }

    changeHandler = (event) => {
        this.props.searchVal(event.target.value)
    }

    hideSearchPage = () => {
        this.props.setSearchPageVisibility(false)
        this.props.searchVal('')
    }

    render() {
        const history = createBrowserHistory();
        return (
            <>
                {!this.props.books.loading &&
                    <Router history={history}>
                        <Header
                            mainSearchEnter={(event) => this.mainSearchEnter(event)}
                            mainSearchClick={this.mainSearchClick}
                            hideSearchPage={this.hideSearchPage}
                            changeHandler={(event) => this.changeHandler(event)}
                            searchVal={this.props.searchValue}
                        />
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact path="/"
                                    component={(props) => <Home {...props} />}
                                />
                                <Route exact path="/about"
                                    component={(props) => <About pageTitle="About Us" {...props} />}
                                />
                                <Route exact path="/guestBook" component={(props) => <GuestBook pageTitle="Guest Book" {...props} />} />
                                <Route exact path="/orderOnline" component={(props) => <OrderOnline {...props} pageTitle="Order Online" {...props} />} />
                                <Route exact path="/download" component={(props) => <Download pageTitle="Download" {...props} />} />
                                <Route exact path="/keyBooks" component={(props) => <KeyBooks pageTitle="Key Books" {...props} />} />
                                <Route exact path="/contact" component={(props) => <Contact pageTitle="Contact Us" {...props} />} />
                                <Route exact path="/print" component={(props) => <Print pageTitle="Print/ Publish" {...props} />} />
                                <Route exact path="/searchCat/:id" component={(props) => <SearchCat pageTitle="Books" {...props} />} />

                                <Route exact path="/search"
                                    component={(props) => <SearchedProducts
                                        pageTitle="Search" {...props}
                                        hideSearchPage={this.hideSearchPage}
                                        searchValue={this.props.searchValue} />} />

                                <Route exact path="/bookDetail/:id" component={(props) => <BookDetails pageTitle="Book Details" {...props} />} />
                                <Route exact path="/moreDetail/:id" component={(props) => <MoreDetails pageTitle="More Details" {...props} />} />
                                <Route path="*" component={(props) => <NotFound pageTitle="Not Found" {...props} />} />
                            </Switch>
                        </Suspense>
                        <Footer />
                    </Router>
                }
                {this.props.books.loading &&
                    <div className="loader">
                        <div className="preLoader">
                            <span className="loader-inner-1"></span>
                            <span className="loader-inner-2"></span>
                            <span className="loader-inner-3"></span>
                            <span className="loader-inner-4"></span>
                        </div>
                    </div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        searchPageVisibility: state.searchVisibilityReducer.searchPageVisibility,
        searchValue: state.searchReducer.searchValue,
        categories: state.categoryReducer,
        books: state.bookDetailsReducer,
        keyBooks: state.keyBooksReducer,
        downloadPdfList: state.downloadPdfListReducer,
        lastOrderId: state.orderIdReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchVal: (value) => dispatch(searchVal(value)),
        setSearchPageVisibility: (value) => dispatch(setSearchPageVisibility(value)),
        getBookDetails: () => dispatch(getBookDetails()),
        getCategories: () => dispatch(getCategories()),
        getKeyBookDetails: () => dispatch(getKeyBookDetails()),
        getDownloadPdfList: () => dispatch(getDownloadPdfList()),
        getLastOrderId: () => dispatch(getLastOrderId()),
        getLastGuestOrderId: () => dispatch(getLastGuestOrderId())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
