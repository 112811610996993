import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './homeSlider.scss';

export class HomeSlider extends Component {
    render() {
        return (
            <div className="slider-head">
                <Container>
                    <h2>Progress<br />With<br /><span>Progress Books</span></h2>
                    <img src={process.env.REACT_APP_PUBLICURL + "images/banner-books.png"} alt="" />
                </Container>
            </div>
        )
    }
}

export default HomeSlider
