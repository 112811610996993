import { GET_BOOK_DETAILS } from '../constants/action-types'

const initialState = {
    books: [],
    relatedBooks: [],
    allBooks: [],
    loading: true
};

const bookDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_BOOK_DETAILS:
            return {
                ...state,
                books: action.payload.books,
                relatedBooks: action.payload.relatedBooks,
                allBooks: action.payload.allBooks,
                loading: false
            };

        default:
            return state;
    }
}
export default bookDetailsReducer;