import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class Product extends Component {
    state = {
        inCart: this.props.inCart
    };

    addToCart = (e) => {
        e.preventDefault();
        this.props.addToCart(this.props.product)
        this.setState({
            inCart: true
        })
    }

    render() {
        const { product } = this.props;
        return (
            <>
                <td align="right">
                    <div className="priceCol">
                        <FontAwesomeIcon icon="rupee-sign"></FontAwesomeIcon>
                        <strong className="ml-1">{product.price}</strong>
                    </div>
                </td>
                <td>
                    {
                        this.state.inCart ? (
                            <Button size="sm" variant="success">
                                Added to cart
                            </Button>
                        ) : (
                            <Button size="sm" variant="primary"
                                onClick={this.addToCart}>
                                Add to cart
                            </Button>
                        )
                    }
                </td>
            </>
        )
    }
}

export default Product;