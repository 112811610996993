import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './404.scss';

export class NotFound extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let history = this.props.history;

        return (
            <section className="notFoundPage">
                <section className="sectionPadding pb-4">
                    <Container>
                        <img src={process.env.REACT_APP_PUBLICURL + "/images/404.png"} alt="404 Error! Page not found." />
                        <h2>Sorry! Page Not Found</h2>
                        <p>The Link you clicked maybe broken or the page may have been removed.</p>
                        <Button size="sm" onClick={history.goBack}>
                            <span>
                                <FontAwesomeIcon icon="long-arrow-alt-left"></FontAwesomeIcon>
                            </span>
                            Go Back
                        </Button>
                    </Container>
                </section>
            </section>
        )
    }
}


export default NotFound

