import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { strip_html_tags } from '../commonActions';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './categories.scss';


export class FeaturedProductsCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            featuredSort: []
        }
    }

    componentDidMount() {
        //Featrued Order 
        let dataArr = this.props.books.books;
        let newFeaturedArr = [];
        dataArr.map((item) => {
            if (item.f_product == 1 && item.status === 'Active') {
                if (item.subcat_name.includes("Class : ", "")) {
                    item.subcat_name = item.subcat_name.replace("Class : ", "")
                }
                item.details = strip_html_tags(item.details)
                return newFeaturedArr.push(item);
            }
        })
        var newFeaturedMapArr = newFeaturedArr.slice(0, 4).map((item) => {
            return [item.subcat_name, item]
        });
        var newFeaturedDataArr = new Map(newFeaturedMapArr);
        var featuredSort = [...newFeaturedDataArr.values()].sort(function (a, b) {
            return a.home_show_order - b.home_show_order;
        });

        this.setState({
            featuredSort: featuredSort
        })
    }

    render() {
        return (
            <>
                <section className="sectionPadding featuredProductsSection">
                    <Container>
                        <div className="categoryHead">
                            <h2>Featured Products</h2>
                        </div>
                        <Row xs={1} md={2}>
                            {this.state.featuredSort.map((val, index) => (
                                <Col key={val.id} md="6" className="mb-3">
                                    <Link to={"/bookDetail/:" + val.id}>
                                        <div className="featuredProductsBox h-100">
                                            {val.image &&
                                                <div className="featuredImage">
                                                    <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="" />
                                                </div>
                                            }
                                            <div className="featuredContent">
                                                {val.class_f &&
                                                    <h4>{val.class_f}</h4>
                                                }
                                                {val.title &&
                                                    <h3>{val.title}</h3>
                                                }
                                                {val.details &&
                                                    <p>{(val.details).substr(0, 120)}...</p>
                                                }
                                                <p
                                                    className="link">See More <FontAwesomeIcon icon="angle-double-right" /></p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>
            </>
        )

    }
}


function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}

export default connect(mapStateToProps)(FeaturedProductsCard)
