import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './footer.scss';


export class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className="footer">
                <Container>
                    <Row className="justify-content-center">
                        <img className="footerLogo" src={process.env.REACT_APP_PUBLICURL + "images/logo-colored.png"} alt="Progress Publishers" />

                        {/* <ul className="footerLinks socialMediaLinks">
                            <li>
                                <a href="#">
                                    <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <FontAwesomeIcon icon={["fab", "youtube"]} />
                                </a>
                            </li>
                        </ul> */}

                        <ul className="footerLinks">
                            <li>
                                <a href="/about">About Us</a>
                            </li>

                            <li>
                                <a href="/download">Download</a>
                            </li>

                            <li>
                                <a href="/guestBook">Guest Book</a>
                            </li>

                            <li>
                                <a href="/orderOnline">Order Online</a>
                            </li>

                            <li>
                                <a href="/print">Print/ Publish</a>
                            </li>

                            <li>
                                <a href="/contact">Contact Us</a>
                            </li>
                        </ul>

                        <div className="copyright">
                            <p>Copyright <FontAwesomeIcon icon="copyright" /> Progress Publishers. All Rights Reserved<sup><FontAwesomeIcon icon="registered" /></sup>.</p>
                        </div>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default Footer
