import React, { Component } from 'react';
import HomeSlider from '../components/homeSlider';
import Categories from '../components/categories';
import About from '../components/about';
import FeaturedProducts from '../components/featuredProducts';

export class Home extends Component {

    constructor(props) {
        super(props)
    }
    

    render() {
        return (
            <div>
                <>
                    <HomeSlider />
                    <Categories {...this.props} />
                    <About />
                    <FeaturedProducts {...this.props} />
                </>

            </div>
        )
    }
}


export default Home

