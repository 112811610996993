import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Alert, Card, ListGroup, Table } from 'react-bootstrap';
import { strip_html_tags } from '../commonActions';
import { Breadcrumbs2 } from '../components/breadCrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './bookDetails.scss';
import { throwServerError } from '@apollo/client';

export class MoreDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailId: '',
            isbn: undefined,
            price: undefined,
            bookDetail: undefined
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var path = this.props.history.location.pathname;
        let searchString = parseInt(path.split(':')[1])

        let allBooksArr = this.props.books.allBooks;

        let booksArr = this.props.books.books;
        let relatedBooksArr = this.props.books.relatedBooks;
        let newRelBooksArr = [];

        this.setState({
            detailId: searchString
        },
            () => {
                newRelBooksArr = allBooksArr.filter((item) => {
                    if (item.id == this.state.detailId) {
                        relatedBooksArr.map((rb) => {
                            if (rb.isbn === item.isbn && rb.id != this.state.detailId
                                && rb.price === item.price) {
                                this.setState({
                                    isbn: rb.isbn,
                                    price: rb.price
                                })
                            }
                        })
                    }
                })
            })
    }

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }

    render() {
        let allBooksArr = this.props.books.allBooks;
        const history = this.props.history;

        return (
            <>
                <Breadcrumbs2 pageTitle="Search" />
                <section className="contentSection bookDetailPage">
                    <Container>
                        <div className="shadowContentSection">
                            <Row>
                                {allBooksArr?.length && allBooksArr?.map((val) => {
                                    if (this.state.isbn === val.isbn && val.cat_id == 2 && this.state.price === val.price) {
                                        return (
                                            <Card key={val.id} className="bookGrid p-0 border-0">
                                                <Card.Body className="p-0 d-flex">
                                                    <Col md={5} className="h-100">
                                                        {val.image &&
                                                            <div className="bookImgBox h-100">
                                                                <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="English" />
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        <div className="bookDesc">
                                                            <div className="d-flex justify-content-between align-items-start">
                                                                {val.title &&
                                                                    <h4>{val.title}
                                                                        <small>
                                                                            {val.page &&
                                                                                <span>Pages: {val.page} | </span>
                                                                            }
                                                                            {val.color &&
                                                                                <span>
                                                                                    {val.color}
                                                                                </span>
                                                                            }
                                                                        </small>

                                                                    </h4>
                                                                }
                                                                {val.class_f ?
                                                                    <p className="mb-0 ml-1">{val.class_f}</p>
                                                                    :
                                                                    <>{val.subcat_name &&
                                                                        <p className="mb-0 ml-1">{val.subcat_name}</p>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            {val.price &&
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    <h3>
                                                                        <FontAwesomeIcon className="ico" icon="rupee-sign"></FontAwesomeIcon>
                                                                        {val.price}</h3>
                                                                </div>
                                                            }
                                                            {val.details &&
                                                                <p className="detail">{strip_html_tags(val.details)}</p>
                                                            }
                                                            <ListGroup className="bookMeta" horizontal>
                                                                {val.team &&
                                                                    <ListGroup.Item>
                                                                        <span className="author-title">Author</span>
                                                                        <span className="author-name">{strip_html_tags(val.team)}</span>
                                                                    </ListGroup.Item>
                                                                }
                                                                {val.size2 &&
                                                                    <ListGroup.Item>
                                                                        <span className="author-title">Size</span>
                                                                        <span className="author-name">{val.size2}</span>
                                                                    </ListGroup.Item>
                                                                }
                                                                {val.isbn &&
                                                                    <ListGroup.Item>
                                                                        <span className="author-title">ISBN</span>
                                                                        <span className="author-name">{val.isbn}</span>
                                                                    </ListGroup.Item>
                                                                }
                                                            </ListGroup>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex justify-content-end">
                                                            <Button onClick={history.goBack}>Go Back</Button>
                                                        </div>
                                                    </Col>
                                                </Card.Body>
                                            </Card>

                                        )
                                    }
                                })
                                }
                            </Row>
                        </div>
                    </Container>
                </section>
            </>

        )
    }
}


function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}


export default connect(mapStateToProps)(MoreDetails);
