import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBanner from '../components/pageBanner';
import './download.scss';

export class Download extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding downloadPage pb-5">
                    <Container>
                        <a href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList.pdfList[0].bookList} className="download-item">
                            <span className="download-item-icon">
                                <FontAwesomeIcon icon="book"></FontAwesomeIcon>
                            </span>
                            <aside>
                                <h2>
                                    Our Books
                                    <small>View books list</small>
                                </h2>
                                <Button>
                                    <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                                </Button>
                            </aside>
                        </a>

                        <a href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList.pdfList[0].catalogue} className="download-item">
                            <span className="download-item-icon">
                                <FontAwesomeIcon icon="book-open"></FontAwesomeIcon>
                            </span>
                            <aside>
                                <h2>
                                    Catalogue
                                    <small>Download our catalogue</small>
                                </h2>
                                <Button>
                                    <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                                </Button>
                            </aside>
                        </a>

                        <a href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList.pdfList[0].priceList} className="download-item">
                            <span className="download-item-icon">
                                <FontAwesomeIcon icon="list-alt"></FontAwesomeIcon>
                            </span>
                            <aside>
                                <h2>
                                    Price List
                                    <small>View price list</small>
                                </h2>
                                <Button>
                                    <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                                </Button>
                            </aside>
                        </a>
                        <a href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList.pdfList[0].orderFormPrint} className="download-item">
                            <span className="download-item-icon">
                                <FontAwesomeIcon icon="print"></FontAwesomeIcon>
                            </span>
                            <aside>
                                <h2>
                                    Order Form
                                    <small>Printable form</small>
                                </h2>
                                <Button>
                                    <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                                </Button>
                            </aside>
                        </a>
                        <a href={process.env.REACT_APP_PUBLICURL + "pdf/" + this.props.downloadPdfList.pdfList[0].orderForm} className="download-item">
                            <span className="download-item-icon">
                                <FontAwesomeIcon icon="file-alt"></FontAwesomeIcon>
                            </span>
                            <aside>
                                <h2>
                                    Order Form
                                    <small>Online form</small>
                                </h2>
                                <Button>
                                    <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                                </Button>
                            </aside>
                        </a>
                    </Container>
                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        downloadPdfList: state.downloadPdfListReducer
    };
}

export default connect(mapStateToProps)(Download);

