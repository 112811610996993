import {
    SHOW_SEARCH_PAGE,
    SEARCH_VALUE,
    GET_CATEGORIES,
    CATEGORIES_ERRORS,
    GET_BOOK_DETAILS,
    BOOK_DETAILS_ERRORS,
    GET_KEYBOOKS_DETAILS,
    KEYBOOKS_DETAILS_ERRORS,
    GET_DOWNLOAD_PDFS,
    GET_DOWNLOAD_PDFS_ERRORS,
    GET_LAST_ORDER_ID,
    GET_LAST_ORDER_ID_ERRORS,
    GET_LAST_GUEST_ORDER_ID,
    GET_LAST_GUEST_ORDER_ID_ERRORS
} from "../constants/action-types";
import Axios from 'axios';

const { REACT_APP_APIPATH } = process.env;

const basePath = REACT_APP_APIPATH

export function setSearchPageVisibility(visibility) {
    return {
        type: SHOW_SEARCH_PAGE,
        visibility
    }
};

export function searchVal(value) {
    return {
        type: SEARCH_VALUE,
        value
    }
};

export function booksFilterData(payload) {
    return {
        type: 'FILTER_DATA',
        payload
    }
};

export const addToCart = (product) => {

    return {
        type: 'ADD_TO_CART',
        payload: {
            product,
            quantity: 1
        }
    }
};

export const removeFromCart = (productId, total) => {

    return {
        type: 'REMOVE_FROM_CART',
        payload: {
            productId: productId,
            total
        }
    }
};

export const updateCartQuantity = (productId, quantity,
    total) => {

    return {
        type: 'UPDATE_CART_QUANTITY',
        payload: {
            productId,
            quantity: quantity,
            total: total
        }
    }
};

export const cartTotal = (total) => {

    return {
        type: 'CART_TOTAL',
        payload: {
            total: total
        }
    }
};

export const resetCart = () => {

    return {
        type: 'RESET_CART'
    }
};

export const getCategories = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getCategories.php')
        dispatch({
            type: GET_CATEGORIES,
            payload: res.data.categories
        })
    }
    catch (e) {
        dispatch({
            type: CATEGORIES_ERRORS,
            payload: console.log(e),
        })
    }
}

export const getBookDetails = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getBookDetails.php')
        const books = [];
        res.data.books.forEach(item => {
            books.push(item);
        });

        dispatch({
            type: GET_BOOK_DETAILS,
            payload: {
                "books": res.data.books,
                "relatedBooks": res.data.relatedBooks,
                "allBooks": res.data.allBooksWRelBooks
            }
        })
        return Promise.resolve(res)
    }
    catch (e) {
        dispatch({
            type: BOOK_DETAILS_ERRORS,
            payload: console.log(e),
        })
    }
}

export const getKeyBookDetails = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getKeybookDetails.php')
        const keyBooks = [];

        res.data.books.forEach(item => {
            keyBooks.push(item);
        });

        dispatch({
            type: GET_KEYBOOKS_DETAILS,
            payload: keyBooks
        })
    }
    catch (e) {
        dispatch({
            type: KEYBOOKS_DETAILS_ERRORS,
            payload: console.log(e),
        })
    }
}

export const getDownloadPdfList = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getDownloadPdfList.php')

        dispatch({
            type: GET_DOWNLOAD_PDFS,
            payload: res.data.pdfList
        })
    }
    catch (e) {
        dispatch({
            type: GET_DOWNLOAD_PDFS_ERRORS,
            payload: console.log(e),
        })
    }
}

export const getLastOrderId = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getLastOrderId.php')
        dispatch({
            type: GET_LAST_ORDER_ID,
            payload: res.data.lastOrderId[0]["max(id)"]
        })
        return Promise.resolve(res)
    }
    catch (e) {
        dispatch({
            type: GET_LAST_ORDER_ID_ERRORS,
            payload: console.log(e),
        })
    }
}

export const getLastGuestOrderId = () => async dispatch => {
    try {
        const res = await Axios.get(basePath + 'getLastGuestOrderId.php')
        dispatch({
            type: GET_LAST_GUEST_ORDER_ID,
            payload: res.data.lastOrderId[0]["max(id)"]
        })
        return Promise.resolve(res)
    }
    catch (e) {
        dispatch({
            type: GET_LAST_GUEST_ORDER_ID_ERRORS,
            payload: console.log(e),
        })
    }
}
