import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from './breadCrumbs';
import './pageBanner.scss';

export class PageBanner extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <section className="pageBanner">
                <div className="overlay">
                    <Container>
                        <h2>{this.props.pageTitle}</h2>
                    </Container>
                </div>
                <div className="breadCrumbBox">
                    <Container>
                        <Breadcrumbs pageTitle={this.props.pageTitle} />
                    </Container>
                </div>

            </section>
        )
    }
}

export default PageBanner
