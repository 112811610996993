import { GET_KEYBOOKS_DETAILS } from '../constants/action-types'

const initialState = {
    keyBooks: [],
    loading: true
};

const keyBooksReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_KEYBOOKS_DETAILS:
            return {
                ...state,
                keyBooks: action.payload,
                loading: false
            };

        default:
            return state;
    }
}
export default keyBooksReducer;