import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dynamicSort } from '../commonActions';
import PageBanner from '../components/pageBanner';
import PaginationPg from '../components/pagination';
import './searchCat.scss';

export class SearchCat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            searchCatId: '',
            searchSubCatId: '',
            itemsPerPage: 12
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let history = this.props.history;
        var path = history.location.pathname;
        let searchString = path.split(':')[1]
        let searchCatId = path.split('&')[1]
        let searchSubCatId = path.split('&')[2]
        this.setState({
            searchValue: searchString.replace(/\&.+/g, "$'"),
            searchCatId: searchCatId,
            searchSubCatId: searchSubCatId
        })
    }

    render() {

        let history = this.props.history;
        let booksArr = this.props.books.books;
        let filteredBooks = [];

        filteredBooks = booksArr.filter((item) => {
            if (item.status === 'Active'
                && item.pr_deactivate === '0'
                && item.cat_id == this.state.searchCatId
                && item.subcat_id == this.state.searchSubCatId) {
                return Object.values(item).toString().toLowerCase().includes(this.state.searchValue.toLowerCase())
            }
        })
        var sortedFilteredBooks = [...filteredBooks.values()].sort(dynamicSort("position"));


        const itemsToRepeat = (val, index) => {
            return (
                <Col sm={3} key={index}>
                    <Link to={"/bookDetail/:" + val.id}>
                        <Card className="bookGrid p-0 border-0">
                            <Card.Body className="p-0">
                                {val.image &&
                                    <div className="bookImgBox">
                                        <img src={process.env.REACT_APP_PUBLICURL + "images/books/" + val.image} alt="English" />
                                        <div className="linkIcoBox">
                                            <FontAwesomeIcon className="linkIco" icon="link"></FontAwesomeIcon>
                                        </div>
                                    </div>
                                }
                                <div className="bookDesc">

                                    <div className="d-flex justify-content-between align-items-center">
                                        {val.subject &&
                                            <h6>{val.subject}</h6>
                                        }
                                        {val.subcat_name &&
                                            <p className="mb-0 ml-1">{val.subcat_name}</p>
                                        }
                                    </div>
                                    {val.title &&
                                        <h4>{val.title}</h4>
                                    }

                                    <Button type="button" variant="primary" size="sm">View More</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            )
        }

        return (
            <section className="searchPage">
                <PageBanner {...this.props} pageTitle={this.state.searchValue} />
                <section className="sectionPadding">
                    <Container>
                        <div className="categoryHead d-flex justify-content-between">
                            <h2>{this.state.searchValue ? this.state.searchValue : 'Search Result'} BOOKS</h2>
                            <Button onClick={history.goBack}>Go Back</Button>
                        </div>

                        <Row className="bookGridFlex">
                            <PaginationPg
                                items={sortedFilteredBooks}
                                itemsToRepeat={(val, index) => itemsToRepeat(val, index)}
                                itemsPerPage={this.state.itemsPerPage}
                            />
                        </Row>
                    </Container>
                </section>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}

export default connect(mapStateToProps)(SearchCat);

