import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import PageBanner from '../components/pageBanner';
import { getLastGuestOrderId } from "../actions";

import './guestBook.scss';

const { REACT_APP_APIPATH } = process.env;

export class GuestBook extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            title: '',
            f_name: '',
            l_name: '',
            phone: '',
            email: '',
            fax: '',
            houseNo: '',
            street: '',
            city: '',
            address: '',
            zip: '',
            stateName: '',
            country: '',
            instituteName: '',
            instituteHead: '',
            schoolTimings: '',
            officeTimings: '',
            schoolMedium: '',
            schoolStrength: '',
            syllabus: '',
            sessionMonth: '',
            schoolFrom: '',
            schoolTo: '',
            schoolBelongs: '',
            booksDecision: '',
            sampleDeliveryTime: '',
            bookSupplyThrough: '',
            railwayStation: '',
            transportCompany: '',
            transportGodown: '',
            bankNameAddress: '',
            remarks: '',
            guestOrderId: '',
            validated: false,
            sending: false,
            mailSent: false
        }
        this.prev = this.prev.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }


    handleSubmit = event => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({
            validated: true
        })

        if (this.state.currentStep === 1 &&
            form.checkValidity() === true) {

            let formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries())

            let currentStep = this.state.currentStep
            currentStep = currentStep >= 1 ? 2 : currentStep + 1
            this.setState({
                currentStep: currentStep,
                step1FormData: formDataObj
            })
        }

        if (this.state.currentStep === 2 &&
            form.checkValidity() === true
        ) {
            let formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries())

            // let date = new Date(),
            //     randomNumber = Math.floor(Math.random() * (999 - 100 + 1) + 100),
            //     components = [
            //         'G-',
            //         date.getDate(),
            //         (date.getMonth() + 1),
            //         date.getFullYear(),
            //         '-',
            //         randomNumber
            //     ],
            //     combinedNumber = components.join("");


            this.setState({
                step2FormData: { formDataObj }
            },
                () => {
                    this.setState({
                        finalFormData: { ...this.state.step1FormData, ...this.state.step2FormData },
                        sending: true
                    },
                        () => {
                            Axios.post(REACT_APP_APIPATH + "guestOrder.php", {
                                data: this.state.finalFormData,
                                headers: {
                                    'Content-type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                if (response.status == 200) {
                                    if (response.data.success === 1) {
                                        this.setState({
                                            currentStep: 1,
                                            title: '',
                                            f_name: '',
                                            l_name: '',
                                            phone: '',
                                            email: '',
                                            fax: '',
                                            houseNo: '',
                                            street: '',
                                            city: '',
                                            address: '',
                                            zip: '',
                                            stateName: '',
                                            country: '',
                                            instituteName: '',
                                            instituteHead: '',
                                            schoolTimings: '',
                                            officeTimings: '',
                                            schoolMedium: '',
                                            schoolStrength: '',
                                            syllabus: '',
                                            sessionMonth: '',
                                            schoolFrom: '',
                                            schoolTo: '',
                                            schoolBelongs: '',
                                            booksDecision: '',
                                            sampleDeliveryTime: '',
                                            bookSupplyThrough: '',
                                            railwayStation: '',
                                            transportCompany: '',
                                            transportGodown: '',
                                            bankNameAddress: '',
                                            remarks: '',
                                            guestOrderId: response.data.guestOrderId,
                                            validated: false,
                                            mailSent: true,
                                            sending: false,
                                            errorSending: false
                                        })
                                    }
                                    if (response.data.success === 0) {
                                        this.setState({
                                            mailSent: false,
                                            sending: false,
                                            errorSending: true
                                        })
                                    }
                                }
                                if (response.data.status === 'ERROR') {
                                    this.setState({
                                        currentStep: 1,
                                        title: '',
                                        f_name: '',
                                        l_name: '',
                                        phone: '',
                                        email: '',
                                        fax: '',
                                        houseNo: '',
                                        street: '',
                                        city: '',
                                        address: '',
                                        zip: '',
                                        stateName: '',
                                        country: '',
                                        instituteName: '',
                                        instituteHead: '',
                                        schoolTimings: '',
                                        officeTimings: '',
                                        schoolMedium: '',
                                        schoolStrength: '',
                                        syllabus: '',
                                        sessionMonth: '',
                                        schoolFrom: '',
                                        schoolTo: '',
                                        schoolBelongs: '',
                                        booksDecision: '',
                                        sampleDeliveryTime: '',
                                        bookSupplyThrough: '',
                                        railwayStation: '',
                                        transportCompany: '',
                                        transportGodown: '',
                                        bankNameAddress: '',
                                        remarks: '',
                                        guestOrderId: '',
                                        validated: false,
                                        mailSent: true,
                                        sending: false,
                                        errorSending: true
                                    })
                                }
                            }
                            ).then(() => {
                                console.log('')
                            })
                        })

                })
        }
    }

    prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-secondary"
                    type="button" onClick={this.prev}>
                    Previous
                </button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 2) {
            return (
                <button
                    className="btn btn-primary"
                    type="submit"
                >
                    Next
                </button>
            )
        }
        if (currentStep === 2) {
            return (
                <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.sending || false}
                >
                    Submit
                    {this.state.sending &&
                        <FontAwesomeIcon className="ml-2" icon="spinner" spin />
                    }
                </button>
            )
        }
        return null;
    }

    render() {
        return (
            <section className="guestBookPage">
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding">
                    <Container>
                        {
                            !this.state.mailSent &&
                            <>
                                <ul className="steps">
                                    <li className={this.state.currentStep == 1 ? 'active' : ''}>
                                        <span className="dot">
                                            <FontAwesomeIcon icon="user" />
                                        </span>
                                        <span>Personal Details</span>
                                    </li>
                                    <li className={this.state.currentStep == 2 ? 'active' : ''}>
                                        <span className="dot">
                                            <FontAwesomeIcon icon="university" />
                                        </span>
                                        <span>Other Details</span>
                                    </li>
                                </ul>
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <small className="text-right d-block mb-2 font-italic">Fields marked with <span className="required">*</span> are required.</small>
                                    <div className="formBox">

                                        {this.state.currentStep === 1 &&
                                            <>
                                                <Form.Row>
                                                    <Form.Group xs="4" md="2" as={Col} controlId="formGridTitle">
                                                        <Form.Label>Title<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            as="select"
                                                            defaultValue={this.state.title}
                                                            name="title"
                                                            onChange={this.handleChange}
                                                            required>
                                                            <option value="">...Select...</option>
                                                            <option value="Mr.">Mr.</option>
                                                            <option value="Mrs.">Mrs.</option>
                                                            <option value="Ms.">Ms.</option>
                                                            <option value="Sr.">Sr.</option>
                                                            <option value="Fr.">Fr.</option>
                                                            <option value="Br.">Br.</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridFirstName">
                                                        <Form.Label>First Name<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Enter First Name"
                                                            name="f_name"
                                                            defaultValue={this.state.f_name}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter first name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridLastName">
                                                        <Form.Label>Last Name<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            name="l_name"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.l_name}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter last name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridPhone">
                                                        <Form.Label>Phone<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="number"
                                                            onInput={() => this.value |= 0}
                                                            placeholder="Enter your phone number"
                                                            name="phone"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.phone}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid phone number!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridEmail">
                                                        <Form.Label>Email<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="email"
                                                            name="email"
                                                            defaultValue={this.state.email}
                                                            onChange={this.handleChange}
                                                            required />
                                                        <Form.Control.Feedback type="invalid">Please enter valid email id!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridFax">
                                                        <Form.Label>Fax</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            name="fax"
                                                            placeholder="Enter Fax"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.fax} />
                                                        <Form.Control.Feedback type="invalid">Please enter valid fax!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridHouseNo">
                                                        <Form.Label>House/ Lane No<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="houseNo"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.houseNo}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid House/ Lane No!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridStreet">
                                                        <Form.Label>Street<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="street"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.street}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid street!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridCity">
                                                        <Form.Label>City<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="city"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.city}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid city!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Group controlId="formGridAddress">
                                                    <Form.Label>Address<span className="required">*</span></Form.Label>
                                                    <Form.Control
                                                        size="lg"
                                                        as="textarea"
                                                        rows={2}
                                                        name="address"
                                                        placeholder="Address"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.address}
                                                        required />
                                                    <Form.Control.Feedback type="invalid">Please enter valid address!</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridZip">
                                                        <Form.Label>ZIP<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="zip"
                                                            type="number"
                                                            onInput={() => this.value |= 0}
                                                            required
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.zip}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid Zip code!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridStateName">
                                                        <Form.Label>State<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="stateName"
                                                            required
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.stateName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid Satte!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridCountry">
                                                        <Form.Label>Country<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            as="select"
                                                            name="country"
                                                            defaultValue={this.state.country}
                                                            onChange={this.handleChange}
                                                            required
                                                        >
                                                            <option value="">(Select Country) </option>
                                                            <option value="India">India </option>
                                                            <option value="Afghanistan">Afghanistan </option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="China">China</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="vietnam">vietnam</option>
                                                            <option value="Others">Others</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                            </>
                                        }

                                        {this.state.currentStep === 2 &&
                                            <>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridInstituteName">
                                                        <Form.Label>Name of the Institution<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder=""
                                                            name="instituteName"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.instituteName}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter valid Institute Name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridInstituteHead">
                                                        <Form.Label>Head of the Institution<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder=""
                                                            name="instituteHead"
                                                            defaultValue={this.state.instituteHead}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter Head of Institute!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridSchoolTimings">
                                                        <Form.Label>School/ Institution Timings</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder=""
                                                            name="schoolTimings"
                                                            defaultValue={this.state.schoolTimings}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridOfficeTimings">
                                                        <Form.Label>Office Timings</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.officeTimings}
                                                            name="officeTimings" />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridSchoolMedium">
                                                        <Form.Label>Medium of School</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            type="text"
                                                            placeholder=""
                                                            name="schoolMedium"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.schoolMedium}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridSchoolStrength">
                                                        <Form.Label>Strength of School (Approx.)</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="schoolStrength"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.schoolStrength}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridSyllabus">
                                                        <Form.Label>Syllabus followed</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="syllabus"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.syllabus}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridSessionMonth">
                                                        <Form.Label>Session Starts from (Month)</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="sessionMonth"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.sessionMonth}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="8" controlId="formGridSchoolFromTo">
                                                        <Form.Label>School is</Form.Label>
                                                        <Form.Row>
                                                            <Form.Label column="sm" md={2}>
                                                                From Class</Form.Label>
                                                            <Col>
                                                                <Form.Control
                                                                    size="lg"
                                                                    name="schoolFrom"
                                                                    onChange={this.handleChange}
                                                                    defaultValue={this.state.schoolFrom}
                                                                />
                                                            </Col>

                                                            <Form.Label column="sm" md={2}>
                                                                To Class</Form.Label>
                                                            <Col>
                                                                <Form.Control
                                                                    size="lg"
                                                                    name="schoolTo"
                                                                    onChange={this.handleChange}
                                                                    defaultValue={this.state.schoolTo}
                                                                />
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridSchoolBelongs">
                                                        <Form.Label>School belongs to</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="schoolBelongs"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.schoolBelongs}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridBooksDecision">
                                                        <Form.Label>Decision of the books made by</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="booksDecision"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.booksDecision}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridSampleDeliveryTime">
                                                        <Form.Label>Sample should reach by</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="sampleDeliveryTime"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.sampleDeliveryTime}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridBookSupplyThrough">
                                                        <Form.Label>Book supply is through school or M/S</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="bookSupplyThrough"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.bookSupplyThrough}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md controlId="formGridRailwayStation">
                                                        <Form.Label>Nearest Railway Station</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="railwayStation"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.railwayStation}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridTransportCompany">
                                                        <Form.Label>Transport Company</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="transportCompany"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.transportCompany}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md controlId="formGridTransportGodown">
                                                        <Form.Label>Nearest Transport Godown</Form.Label>
                                                        <Form.Control
                                                            size="lg"
                                                            name="transportGodown"
                                                            onChange={this.handleChange}
                                                            defaultValue={this.state.transportGodown}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Group controlId="formGridBankNameAddress">
                                                    <Form.Label>Bank's Name &amp; Address</Form.Label>
                                                    <Form.Control
                                                        size="lg"
                                                        as="textarea"
                                                        rows={2}
                                                        name="bankNameAddress"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.bankNameAddress}
                                                        placeholder="" />
                                                </Form.Group>
                                                <Form.Group controlId="formGridRemarks">
                                                    <Form.Label>Remarks</Form.Label>
                                                    <Form.Control
                                                        size="lg"
                                                        as="textarea"
                                                        rows={2}
                                                        name="remarks"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.remarks}
                                                        placeholder="" />
                                                </Form.Group>
                                            </>
                                        }
                                    </div>
                                    <div className={this.state.currentStep == 1 ? 'bottomButtons justify-content-right' : 'bottomButtons'}>
                                        {this.previousButton()}
                                        {this.nextButton()}
                                    </div>
                                </Form>
                            </>
                        }

                        {this.state.mailSent &&
                            !this.state.errorSending &&
                            <div className="formBox">
                                {this.state.step2FormData && this.state.guestOrderId &&
                                    <Alert className="m-4" variant="success">
                                        <Alert.Heading>Thank You!</Alert.Heading>
                                        <p>Please note down your order id : <strong className="text-uppercase">"{this.state.guestOrderId}"</strong> for future reference.</p>
                                    </Alert>
                                }
                            </div>
                        }


                        {this.state.errorSending &&
                            <div className="formBox">
                                <Alert className="m-4" variant="danger">
                                    <Alert.Heading>Some error occured!</Alert.Heading>
                                    <p>Please try again after sometime.</p>
                                </Alert>
                            </div>
                        }
                    </Container>
                </section>
            </section>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        lastOrderId: state.orderIdReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLastGuestOrderId: () => {
            dispatch(getLastGuestOrderId())
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(GuestBook)

