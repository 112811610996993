
import React, { Component } from 'react';
import { Container, Row, Pagination } from 'react-bootstrap';

export class PaginationPg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : 5
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    render() {
        const { currentPage, itemsPerPage } = this.state;

        // Logic for displaying current todos
        const indexOfLastTodo = currentPage * itemsPerPage;
        const indexOfFirstTodo = indexOfLastTodo - itemsPerPage;
        const currentTodos = this.props.items.slice(indexOfFirstTodo, indexOfLastTodo);


        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.props.items.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <Pagination.Item
                    key={number}
                    id={number}
                    active={number === currentPage}
                    onClick={this.handleClick}>
                    {number}
                </Pagination.Item>
            );
        });

        return (
            <>
                {currentTodos.map((val, index) => {
                    return (
                        this.props.itemsToRepeat(val, index)
                    )
                })}

                {pageNumbers.length > 1 &&
                    <div className="border-top d-block w-100 pt-2">
                        <Pagination className="justify-content-end">
                            {renderPageNumbers}
                        </Pagination>
                    </div>
                }
            </>
        )
    }
}



export default PaginationPg