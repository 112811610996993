export const SHOW_SEARCH_PAGE = "SHOW_SEARCH_PAGE";
export const QUANTITY_BOX_FOCUS = "QUANTITY_BOX_FOCUS";
export const SEARCH_VALUE = "SEARCH_VALUE";
export const FILTER_DATA = "FILTER_DATA";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORIES_ERRORS = "CATEGORIES_ERRORS";
export const GET_BOOK_DETAILS = "GET_BOOK_DETAILS";
export const BOOK_DETAILS_ERRORS = "BOOK_DETAILS_ERRORS";
export const GET_KEYBOOKS_DETAILS = "GET_KEYBOOKS_DETAILS";
export const KEYBOOKS_DETAILS_ERRORS = "KEYBOOKS_DETAILS_ERRORS";
export const GET_DOWNLOAD_PDFS = "GET_DOWNLOAD_PDFS";
export const GET_DOWNLOAD_PDFS_ERRORS = "GET_DOWNLOAD_PDFS_ERRORS";
export const GET_LAST_ORDER_ID = "GET_LAST_ORDER_ID";
export const GET_LAST_ORDER_ID_ERRORS = "GET_LAST_ORDER_ID_ERRORS";
export const GET_LAST_GUEST_ORDER_ID = "GET_LAST_GUEST_ORDER_ID";
export const GET_LAST_GUEST_ORDER_ID_ERRORS = "GET_LAST_GUEST_ORDER_ID_ERRORS";