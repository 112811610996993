import React, { Component } from 'react';
import { connect } from 'react-redux'
import CategoryCard from './categoryCard';
import './categories.scss';

export class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: this.props.categories
        }
    }
    
    render() {
        const { categories } = this.props
        return (
            <>
                {
                    categories.categories.length && categories.categories.map((cat, index) => {
                        return (
                            <CategoryCard key={cat.id} catTitle={cat.cat_name} {...this.props} />
                        )
                    })
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.categoryReducer
    }
};

export default connect(mapStateToProps)(Categories)
