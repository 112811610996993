import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBanner from '../components/pageBanner';
import Axios from 'axios';
import './contact.scss';

const { REACT_APP_APIPATH } = process.env;

export class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_name: '',
            phone: '',
            email: '',
            address: '',
            remarks: '',
            catalogue: false,
            bookList: false,
            priceList: false,
            validated: false,
            mailSent: false,
            sending: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleCheckbox = event => {
        const { name, value } = event.target
        const checked = event.target.checked
        this.setState({
            [name]: checked
        })
    }


    handleSubmit = event => {
        event.preventDefault();

        const {
            contact_name,
            phone,
            email,
            address,
            remarks,
            catalogue,
            bookList,
            priceList
        } = this.state

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({
            validated: true
        })


        if (form.checkValidity() === true) {
            this.setState({
                sending: true
            })
            let formData = new FormData(event.target),
                formDataObj = {
                    contact_name: contact_name,
                    phone: phone,
                    email: email,
                    address: address,
                    remarks: remarks,
                    catalogue: catalogue,
                    bookList: bookList,
                    priceList: priceList
                }
            Axios.post(REACT_APP_APIPATH + "contactQuery.php", {
                data: formDataObj,
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response.status == 200) {
                    this.setState({
                        contact_name: '',
                        phone: '',
                        email: '',
                        address: '',
                        remarks: '',
                        catalogue: false,
                        bookList: false,
                        priceList: false,
                        validated: false,
                        mailSent: true,
                        sending: false,
                        errorSending: false
                    })
                }
                if (response.data.status === 'ERROR') {
                    this.setState({
                        contact_name: '',
                        phone: '',
                        email: '',
                        address: '',
                        remarks: '',
                        catalogue: false,
                        bookList: false,
                        priceList: false,
                        validated: false,
                        mailSent: true,
                        errorSending: true,
                        sending: false
                    })
                }
            }
            ).then(() => {
                console.log('')
            })
        }
    }

    render() {
        return (
            <>
                <PageBanner {...this.props} pageTitle={this.props.pageTitle} />
                <section className="sectionPadding contactPage pb-5">
                    <Container>
                        <Row className="contactDetails">
                            <Col sm={3}>
                                <p>
                                    <FontAwesomeIcon className="icon" icon="map-pin"></FontAwesomeIcon>
                                    <span className="contactdetailTitle">Find us at</span>
                                    <span className="businessName">Progress Publishers</span>
                                    <span>F-2/4 Krishna Nagar, Delhi 110051, INDIA</span>
                                </p>
                            </Col>
                            <Col sm={3}>
                                <p>
                                    <FontAwesomeIcon className="icon" icon="phone-alt"></FontAwesomeIcon>
                                    <span className="contactdetailTitle">Call Us at</span>
                                    <span>+91-9899066770, +91-11-22092513</span>
                                </p>
                            </Col>
                            <Col sm={3}>
                                <p>
                                    <FontAwesomeIcon className="icon" icon="sms"></FontAwesomeIcon>
                                    <span className="contactdetailTitle">SMS &amp; Whatsapp us at</span>
                                    <span>+91-9013370747</span>
                                </p>
                            </Col>
                            <Col sm={3}>
                                <p>
                                    <FontAwesomeIcon className="icon" icon="envelope"></FontAwesomeIcon>
                                    <span className="contactdetailTitle">Email us at</span>
                                    <span>progresspublishers@gmail.com, info@progresspublishers.com</span>
                                </p>
                            </Col>
                        </Row>

                        <Row noGutters="true" className="my-4">
                            <Col sm={5} lg={4}>
                                <iframe
                                    className="mapContainer"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15243.89261916216!2d77.27342484314481!3d28.657598753780892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfc9a6a175ae1%3A0xa366ecc5c128d57c!2sProgress%20Publishers!5e0!3m2!1sen!2sin!4v1618071083748!5m2!1sen!2sin" loading="lazy">
                                </iframe>
                            </Col>
                            <Col>
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <div className="formBox">
                                        <h2>Feel free to <span>Contact Us</span></h2>
                                        {
                                            !this.state.mailSent &&
                                            <>
                                                <small className="d-block mb-5">Fields marked with <span className="required">*</span> are required.</small>

                                                <Form.Row>
                                                    <Form.Group as={Col} sm="6" lg="4" controlId="contact_name">
                                                        <Form.Label>Name<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            name="contact_name"
                                                            defaultValue={this.state.contact_name}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter name!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="6" lg="4" controlId="phone">
                                                        <Form.Label>Phone<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            onInput={() => this.value |= 0}
                                                            placeholder="Enter Phone"
                                                            name="phone"
                                                            defaultValue={this.state.phone}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter phone!</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="12" lg="4" controlId="email">
                                                        <Form.Label>Email<span className="required">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Email"
                                                            name="email"
                                                            defaultValue={this.state.email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter email!</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>


                                                <Form.Group controlId="address">
                                                    <Form.Label>Address<span className="required">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Address"
                                                        as="textarea"
                                                        rows={2}
                                                        name="address"
                                                        defaultValue={this.state.address}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please enter address!</Form.Control.Feedback>
                                                </Form.Group>



                                                <Form.Group controlId="remarks">
                                                    <Form.Label>Query/ Remarks/ Discuss<span className="required">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        as="textarea"
                                                        rows={2}
                                                        name="remarks"
                                                        defaultValue={this.state.remarks}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please enter remarks!</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Row>
                                                    <Form.Group as={Col} sm="12" lg="4">
                                                        <Form.Check
                                                            custom
                                                            name="catalogue"
                                                            defaultValue={this.state.catalogue}
                                                            checked={this.state.catalogue}
                                                            onChange={this.handleCheckbox}
                                                            type="checkbox"
                                                            id="custom-checkbox"
                                                            label="Request for Catalogue"
                                                        />

                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="12" lg="4">
                                                        <Form.Check
                                                            custom
                                                            name="bookList"
                                                            defaultValue={this.state.bookList}
                                                            checked={this.state.bookList}
                                                            label="Request for Book List"
                                                            onChange={this.handleCheckbox}
                                                            id="custom-checkbox2"
                                                            type="checkbox"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="12" lg="4">
                                                        <Form.Check
                                                            custom
                                                            name="priceList"
                                                            defaultValue={this.state.priceList}
                                                            checked={this.state.priceList}
                                                            label="Request for Price List"
                                                            onChange={this.handleCheckbox}
                                                            id="custom-checkbox3"
                                                            type="checkbox"

                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </>
                                        }
                                        {this.state.mailSent &&
                                            !this.state.errorSending &&
                                            <Alert className="my-4" variant="success">
                                                <Alert.Heading>Thank You!</Alert.Heading>
                                                <p>Your query has been sent successfully. We will get in touch with you shortly.</p>
                                            </Alert>
                                        }

                                        {this.state.mailSent &&
                                            this.state.errorSending &&
                                            <Alert className="my-4" variant="danger">
                                                <Alert.Heading>Some error occured!</Alert.Heading>
                                                <p>Please try again after sometime.</p>
                                            </Alert>
                                        }
                                    </div>
                                    {!this.state.mailSent &&
                                        <div className="bottomButtons justify-content-right">
                                            <Button type="submit"
                                                disabled={this.state.sending || false}
                                            >Submit
                                                {this.state.sending &&
                                                    <FontAwesomeIcon className="ml-2" icon="spinner" spin />
                                                }
                                            </Button>
                                        </div>
                                    }
                                </Form>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </>
        )
    }
}


export default Contact


