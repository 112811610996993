import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import './about.scss';

export class About extends Component {
    render() {
        let booksArr = this.props.books.books;

        return (
            <section className="sectionPadding aboutSection">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h2>About Us</h2>
                            <p>Progress Publishers is dedicated in the field of education since 1976 by providing books of high quality at very reasonable prices. It has an established track record of maintaining international standards in publishing.</p>
                            <p>Our books are well appreciated and recommended in reputed schools throughout the nation who value education. The books are written and edited by qualified and dedicated teachers, professionals who are specialised in their respective fields. These books employ the most modern and proven..</p>
                            <a href="/about" className="link">Know More <FontAwesomeIcon icon="angle-double-right" /></a>
                        </Col>
                        <Col sm={3}>
                            <div className="aboutIcon">
                                <div className="icoBox orange">
                                    <FontAwesomeIcon icon="book-reader" />
                                </div>
                                <h4><CountUp delay={1} end={booksArr.length} /></h4>
                                <h5>Books to Read</h5>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="aboutIcon">
                                <div className="icoBox pink">
                                    <FontAwesomeIcon icon="clipboard-check" />
                                </div>
                                <h4><CountUp delay={1} end={15000} />+</h4>
                                <h5>Orders Completed</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        books: state.bookDetailsReducer
    };
}


export default connect(mapStateToProps)(About);
