import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import './breadCrumbs.scss';

export const Breadcrumbs = (props) => (
    <div className="breadcrumbs-1">
        <Breadcrumb>
            <Breadcrumb.Item href='/' >Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{props.pageTitle}</Breadcrumb.Item>
        </Breadcrumb>
    </div>
)

export const Breadcrumbs2 = (props) => {
    return (
        <div className="breadcrumbs-2">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href='/' >Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.pageTitle}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
        </div>
    )
}

export default Breadcrumbs
